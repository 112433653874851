<template>
  <div class="container">
    <div class="search" style="display:flex">
      <el-form ref="form" style="flex:1" :inline="true" :model="search" class="demo-form-inline">
        <el-form-item>
          <el-input v-model="search.kname" @change="Search" placeholder="名称"></el-input>
        </el-form-item>
        <el-form-item>
          <el-select v-model="search.category_code" clearable @change="Search" placeholder="所属品类">
            <el-option
              v-for="item in categrylist"
              :key="item.code"
              :label="item.name"
              :value="item.code">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-input v-model="search.product_model_code" @change="Search" placeholder="专属型号"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="search.version_code" @change="Search" placeholder="版本号"></el-input>
        </el-form-item>
      </el-form>
      <div>
        <el-button type="primary" size="small" @click="addCategory">增加</el-button>
      </div>
    </div>
    <!-- 列表 -->
    <el-table border v-bind:data="list">
      <el-table-column label="面板名称">
        <template v-slot="scope">
          <span>{{ scope.row.name }}</span>
        </template>
      </el-table-column>
      <el-table-column label="型号">
        <template v-slot="scope">
          <span>{{ scope.row.product_model_code }}</span>
        </template>
      </el-table-column>
      <el-table-column label="安卓文件key">
        <template v-slot="scope">
          <span>{{ scope.row.android_file }}</span>
        </template>
      </el-table-column>
      <el-table-column label="ios文件key">
        <template v-slot="scope">
          <span>{{ scope.row.ios_file }}</span>
        </template>
      </el-table-column>
      <el-table-column label="所属品类">
        <template v-slot="scope">
          <span>{{ categryNameCode[scope.row.category_code]?categryNameCode[scope.row.category_code]:scope.row.category_code }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template v-slot="scope">
          <router-link :to="'/iotmanage/paneladd/' + scope.row.code" style="margin-right:10px">
            <el-button type="primary" size="small">修改</el-button>
          </router-link>
          <el-button type="danger" size="small" @click="DeletePanel(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @current-change="handleCurrentChange"
      v-model:currentPage="search.page"
      :page-size="search.limit"
      layout="total, prev, pager, next"
      :total="total">
    </el-pagination>
  </div>
</template>

<script type="text/ecmascript-6">
export default {
  name: '',
  mixins: [],
  components: {},
  props: {},
  data () {
    return {
      search: {
        page: 1,
        limit: 10,
        kname: '',
        category_code: '',
        product_model_code: '',
        version_code: ''
      },
      list: [],
      total: 0,
      categrylist: [],
      categryNameCode: {}
    }
  },
  computed: {},
  watch: {},
  methods: {
    GetCategory () {
      this.api.CategoryList({
        page: 1,
        limit: 100,
      }).then(res => {
        if (res.data.code == 200) {
          this.categrylist = res.data.data.data
          res.data.data.data.forEach(item => {
            this.categryNameCode[item.code] = item.name
          })
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    handleCurrentChange(val) {
      this.search.page = val
      this.Search()
    },
    Search () {
      this.api.PanelList(this.search).then(res => {
        if (res.data.code == 200) {
          this.list = res.data.data.data
          this.total = res.data.data.page_info.total
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    addCategory () {
      this.$router.push('/iotmanage/paneladd/0')
    },
    DeletePanel(item) {
      this.$confirm('是否删除该面板?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 删除请求
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消删除'
        });
      })
    },
  },
  filters: {},
  mounted () {
    this.Search()
  },
  created () {
    this.GetCategory()
  }
}
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
</style>
